import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Text from '../components/Text'
import Share from '../components/Share'
import Spacer from '../components/Spacer'
import Download from '../components/Download'
import More from '../components/More'

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`

const NewsTemplate = ({ pageContext, location }) => {
  const { title, content, date, download } = pageContext

  const intl = useIntl()

  return (
    <Layout>
      <SEO title={title} />
      <Content intro>
        <Title top={`News / ${date}`}>{title}</Title>
        <Text centerLists align="center">
          {documentToReactComponents(content.json)}
        </Text>
        {download && (
          <>
            <Spacer size="small" />
            <Download download={download} />
          </>
        )}
        <Spacer size="small" />
        <Share url={location.href} />
        <Spacer size="small" />
        <StyledLink to={`/${intl.locale}/news`}>
          <More label={intl.formatMessage({ id: 'archive' })} />
        </StyledLink>
        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default NewsTemplate
