import React, { useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import ArrowDown from './ArrowDown'
import labelStyle from '../styles/label'

const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 1rem auto;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
`

const Label = styled.div`
  color: ${props => props.theme.colors.primary};
  ${labelStyle}
  margin-bottom: 1rem;
`

const Arrow = styled(animated.div)`
  svg {
    margin: 0 auto;
  }
`

const More = ({ label = 'Weitere', ...props }) => {
  const [isFocus, setIsFocus] = useState(false)
  const animationProps = useSpring({
    transform: isFocus ? 'translateY(8px)' : 'translateY(0px)',
  })

  return (
    <Wrapper
      {...props}
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
    >
      <Label>{label}</Label>
      <Arrow style={animationProps}>
        <ArrowDown />
      </Arrow>
    </Wrapper>
  )
}

export default More
