import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: block;
  height: 34px;
  width: auto;
`
const ArrowDown = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.18 34">
    <defs>
      <clipPath id="a" transform="translate(-444 -522.86)">
        <rect x={444} y="522.86" width="8.18" height={34} fill="none" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" fill="none" stroke="#e63b27">
      <line x1="4.09" x2="4.09" y2="33.27" />
      <polyline points="7.81 29.26 4.09 33.27 0.37 29.26" />
    </g>
  </Svg>
)

export default ArrowDown
