import React from 'react'
import styled from 'styled-components'

import Label from './Label'

const Wrapper = styled.div`
  text-align: center;
`

const Link = styled.a`
  display: inline-block;
  margin-top: 1rem;
`

const Download = ({ download }) => {
  return (
    <Wrapper>
      <Label>Download</Label>
      {download.map(item => (
        <Link key={item.id} href={item.file.url} target="_blank">
          {item.title}
        </Link>
      ))}
    </Wrapper>
  )
}

export default Download
